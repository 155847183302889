.main {
    padding: 3rem 1rem;
}

@media screen and (min-width: 768px) {
    .main {
        padding: 6rem 1rem;
    }

    .mainImg {
        max-width: 65%;
    }
}

@media screen and (min-width: 1200px) {
    .mainImg {
        max-width: 38%;
    }
}