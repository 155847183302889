.navbar-toggler:focus {
    box-shadow: none !important;
}

@media screen and (max-width: 991px) {
    .navbar-collapse.show {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 60px;
        z-index: 2;
        min-width: 150px;
        background-color: var(--secondary-color);
    }
}