.react-responsive-modal-modal {
    max-width: 90vw !important;
    max-height: 90vh !important;
    width: 100% !important;
    height: 100% !important;
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 0.5rem;
}

.react-responsive-modal-closeButton {
    top: 5px !important;
    right: 5px !important;
    width: 40px !important;
    height: 40px !important;
    padding: 10px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: rgb(211 62 62 / 0.8) !important;
    border-radius: 0.5rem;
    z-index: 10;
}

.react-responsive-modal-closeButton svg {
    fill: #fff;
}