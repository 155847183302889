.menuBtn {
    position: fixed;
    top: 3%;
    right: 5%;
    background: none;
    border: none;
    z-index: 100;
    background-color: var(--secondary-bg-trans);
    border-radius: 0.65rem;
    padding: 0.65rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuBtn svg {
    width: 25px;
    height: 25px;
    fill: #fff;
}

.menuWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 0.85);
    z-index: 1000;
    animation-name: fadeIn;
    animation-duration: 0.3s;
}

.navigationSeperator {
    --bulletSize: 15px;
    --seperatorWidth: 2px;
    height: var(--seperatorWidth);
    background-color: #fff;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative;
}

.navigationSeperator::before,
.navigationSeperator::after {
    content: '';
    position: absolute;
    top: 50%;
    width: var(--bulletSize);
    height: var(--bulletSize);
    background-color: #fff;
    transform: translateY(-50%) rotate(45deg);
}

.navigationSeperator::before {
    left: calc(0px - (var(--bulletSize) / 2));
}

.navigationSeperator::after {
    right: calc(0px - (var(--bulletSize) / 2));
}

.linksWrapper {
    max-width: 100%;
    counter-reset: linkCounter;
}

.linksWrapper a {
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    margin-bottom: 0.7rem;
    transition: color ease 0.3s;
}

.linksWrapper a:hover {
    color: var(--main-color);
}

.linksWrapper a::before {
    counter-increment: linkCounter;
    content: counter(linkCounter) ' - ';
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media screen and (min-width: 992px) {
    .menuBtn {
        top: 5%;
    }

    .navigationSeperator {
        width: var(--seperatorWidth);
        height: 300px;
    }

    .navigationSeperator::before,
    .navigationSeperator::after {
        top: initial;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
    }

    .navigationSeperator::before {
        top: calc(0px - (var(--bulletSize) / 2));
    }

    .navigationSeperator::after {
        bottom: calc(0px - (var(--bulletSize) / 2));
    }
}