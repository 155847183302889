.backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgb(0 0 0 / 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}