.headerLogo {
    max-width: 90px;
    background-color: #fff;
    padding: 1rem;
}

.menuBtn {
    background: none;
    border: none;
    z-index: 100;
    background-color: var(--secondary-bg-trans);
    border-radius: 0.65rem;
    padding: 0.65rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuBtn svg {
    width: 25px;
    height: 25px;
    fill: #fff;
}

.logoLink.noMenu {
    justify-content: center;
    padding: 1rem;
}

@media screen and (min-width: 992px) {
    .headerLogo {
        max-width: 130px;
    }

    .menuBtn {
        display: none;
    }

    .logoLink {
        position: relative;
        margin-bottom: -4rem;
    }

    .logoLink.noMenu {
        position: absolute;
        left: 10%;
        top: 3%;
    }
}