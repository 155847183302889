.mediaPreviewBorder {
    border: 4px solid var(--main-color);
    box-shadow: 0 0 10px 4px var(--main-color-lighter);
}

.mediaPreview {
    width: 100%;
    display: block;
    background-color: #000;
    object-fit: contain;
    height: 100%;
    overflow: auto;
}

.mediaPreview.audio {
    padding-bottom: 20%;
}

.mediaPreviewPlaceholder {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.zoomBtn {
    position: absolute;
    top: unset;
    left: unset;
    right: 5px;
    bottom: 5px;
    width: 40px;
    height: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 0.5);
    border-radius: 0.5rem;
    z-index: 10;
}

.zoomWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 5%;
    top: 0;
    left: 0;
    background-color: rgb(0 0 0 / 0.7);
    z-index: 100;
}

.zoomWrapper .mediaPreview {
    background-color: transparent;
}

.closeZoomBtn {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(211 62 62 / 0.8);
}